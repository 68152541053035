<template>
  <div class="sm:flex items-center sm:justify-between">
    <p>
      <span class="font-medium text-xl text-black">{{ $t('Delivery Performance') }}</span>
      <vx-tooltip :text="$t('These figures are updated on an hourly basis.')" class="inline-block">
        <span class="material-icons-round text-black text-base mx-1 cursor-default">info</span>
      </vx-tooltip>
    </p>
    <div class="relative">
      <div class="flex items-center gap-1 justify-end cursor-pointer select-none" @click="toggleDateModal()">
        <span class="material-icons-outlined text-primary">date_range</span>
        <p class="font-medium text-black leading-none">
          {{ gettingDate(new Date(startDate)) + ' - ' + gettingDate(new Date(endDate)) }}
        </p>
        <span class="material-icons-outlined text-black text-2xl">arrow_drop_down</span>
      </div>
      <div :class="filterDateModal ? 'show' : 'hidden'" class="analytics-range rounded-md p-4">
        <div class="grid grid-cols-2 gap-4">
          <div class="md:col-span-1 col-span-2">
            <datepicker :inline="true" name="from" :disabledDates="disabledDatesStart" v-model="startDate"></datepicker>
          </div>
          <div class="md:col-span-1 col-span-2">
            <datepicker :inline="true" name="to" :disabledDates="disabledDates" v-model="endDate"></datepicker>
          </div>
        </div>
        <div class="flex gap-2 mt-4">
          <button @click="toggleDateModal()" class="bg-grey border-0 text-white rounded py-3 w-24 cursor-pointer leading-none">{{ $t('Cancel') }}</button>
          <button @click="applyDate(startDate, endDate)" class="bg-primary border-0 text-white rounded py-3 w-24 cursor-pointer leading-none">{{ $t('Apply') }}</button>
        </div>
      </div>
    </div>
    <div v-if="filterDateModal" @click="toggleDateModal()" class="date-container"></div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'

export default {
  data () {
    return {
      disabledDates: {
        from: new Date()
      },
      disabledDatesStart: {
        to: new Date('1-1-2022'),
        from: new Date()
      },
      startDate: '',
      endDate: '',
      lastStartDate: '',
      lastEndDate: '',
      filterDateModal: false
    }
  },
  watch: {
    'filterDateModal' () {
      this.disabledDatesStart = {
        to: new Date('1-1-2022'),
        from: new Date()
      }
    },
    'startDate' () {
      const date = new Date(this.startDate)
      this.endDate = date < new Date(this.endDate) ? new Date() : date
      this.disabledDates.to = date
    }
  },
  methods: {
    gettingDate (date, formateDate) {
      const locale = (localStorage.getItem('locale') && localStorage.getItem('locale') !== 'null') ? localStorage.getItem('locale') : 'ar'
      const options = {day: '2-digit', month: 'short', year: 'numeric'}
      formateDate = new Date(date).toLocaleDateString(locale, options)
      return formateDate
    },
    initialDate () {
      const current = new Date()
      this.startDate = new Date(current.getFullYear(), current.getMonth(), 1)
      this.endDate = new Date()
      this.lastStartDate = this.startDate
      this.lastEndDate = this.endDate
      this.applyDate()
    },
    applyDate () {
      this.$emit('startDate', new Date(this.startDate).toISOString().split('T')[0])
      this.$emit('endDate', new Date(this.endDate).toISOString().split('T')[0])
      this.$emit('callData')
      this.lastStartDate = this.startDate
      this.lastEndDate = this.endDate
      this.filterDateModal = false
    },
    toggleDateModal () {
      this.startDate = this.lastStartDate
      this.endDate = this.lastEndDate
      this.filterDateModal = !this.filterDateModal
    }
  },
  components: {
    Datepicker
  },
  mounted () {
    this.initialDate()
  }
}
</script>

<style lang="scss">
.date-container {
  position: fixed;
  inset: 0;
  z-index: 99;
}
.analytics-range {
  width: 642px;
  position: absolute;
  top: 35px;
  right: -11.5px;
  z-index: 991;
  background: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);
  border: 1px solid rgba(0, 0, 0, .1);
  &.show {
    animation: showCalender 0.1s linear;
  }
  @keyframes showCalender {
    0% { top: 50px; opacity: 0; }
    100% { top: 35px; opacity: 1; }
  }
  .vdp-datepicker {
    .vdp-datepicker__calendar {
      padding: 4px;
      background: #fff;
      header span:hover {
        border-radius: 10px;
      }
    }
    .cell {
      &.day:not(.blank) {
        background: #fff;
        border-radius: 50% !important;
        margin-bottom: 4px;
        &:hover {
          background: #eee;
        }
      }
      &.day-header {
        background: none;
      }
    }
  }
}
@media (max-width: 767px) {
  .analytics-range {
    width: 330px;
  }
}
</style>