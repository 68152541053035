<template>
 <shipblu-card class="p-3">
  <p class="font-medium text-xl mb-3">{{$t('Geographical Analysis')}}</p>
  <div class="grid lg:grid-cols-5 grid-cols-1 gap-y-4">
    <apex-charts :class="`lg:col-span-2 col-span-1 m-auto ${series.length === 0 ? 'lg:block hidden' : '' }`" width="100%" :style="series.length > 0 ? 'width: 500px; max-width: 100%;' : 'width: 150px;'" type="pie" :options="chartOptions" :series="series"></apex-charts>
    <geographical-table class="w-full lg:col-span-3 col-span-1" :tableHeight="'500px'" :tableHeaders="tableHeaders" :citiesData="citiesData"></geographical-table>
  </div>
 </shipblu-card>
</template>
<script>
import ApexCharts from 'vue-apexcharts'
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import GeographicalTable from './GeographicalTable.vue'
import { sendRequest } from '../../../http/axios/requestHelper'
import i18nData from '@/i18n/i18nData.js'

export default {
  props: ['geoData', 'merchantID', 'startDate', 'endDate'],
  data () {
    return {
      geoLoader: false,
      tableHeaders: [ 
        { text: 'City', field: 'city' },
        { text: 'Picked Orders', field: 'orders_pickup_count' },
        { text: 'Delivered', field: 'orders_delivered_count' },
        { text: 'Delivery %', field: 'delivered_percentage' }
      ],
      citiesData: [],
      series: [],
      chartOptions: {
        chart: {
          width: '100%',
          type: 'pie'
        },
        labels: [],
        colors: ['#1D3D71', '#475F97', '#6E84BF', '#96ABE9', '#A6AABD'],
        stroke: {
          width: 0
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            dataLabels:{
              offset: -20
            }
          }
        },
        dataLabels: {
          formatter (val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            return [name, `${val.toFixed(1)  }%`]
          },
          style: {
            fontSize: '12px'
          }
        },
        legend: {
          show: false
        }
      }
    }
  },
  watch: {
    geoData (val) {
      this.updateChartData(val)
      this.loadCityData()
    },
    '$route.params.lang' () {
      this.updateChartData(this.geoData)
    }
  },
  methods: {
    loadCityData () {
      this.geoLoader = true
      sendRequest(true, false, this, `api/v1/dash/analytical/delivery-performance/cities-distribution?merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`, 'get', null, true,
        (response) => {
          this.citiesData = response.data
          this.geoLoader = false
        }
      )
    },
    updateChartData (val) {
      const governoratesLabels = []
      this.series = val.map(item => item.orders_pickup_count)
      val.map(item => {
        governoratesLabels.push(i18nData[this.$i18n.locale][item.governorate] ? i18nData[this.$i18n.locale][item.governorate] : item.governorate)
      })
      this.chartOptions = {labels: governoratesLabels}
    }
  },
  components: {
    ApexCharts,
    ShipbluCard,
    GeographicalTable
  }
}
</script>
