<template>
  <shipblu-card class="p-3">
    <p class="font-medium text-xl mb-3">{{$t('Flyers Size Contribution')}}</p>
    <apex-charts type="pie" class="m-auto" width="100%" :style="series.length > 0 ? 'width: 400px; max-width: 100%;' : 'width: 150px;'" :options="chartOptions" :series="series"></apex-charts>
  </shipblu-card>
</template>

<script>
import ApexCharts from 'vue-apexcharts'
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'

export default {
  props: ['packageDistribution', 'packageDistributionLoader'],
  data () {
    return {
      series: [],
      chartOptions: { 
        chart: {
          type: 'pie'
        },
        labels:[],
        colors: ['#1D3D71', '#475F97', '#6E84BF', '#96ABE9', '#A6AABD'],
        stroke: {
          width: 0
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            dataLabels: {
              offset: -15
            }
          }
        },
        dataLabels: {
          style: {
            fontSize: '12px'
          },
          formatter (val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            return [name, `${val.toFixed(1)  }%`]
          }
        },
        legend: {
          show: false
        }
      }
    }
  },
  watch: {
    packageDistribution (val) {
      this.series = this.packageDistribution.filter(res => res['package_size'] !== 'OSZ').map(res => res['packages_count'])
      this.chartOptions = {
        labels: val.filter(res => res['package_size'] !== 'OSZ').map(item => item['package_size'].split('-')[0])
      }
    }
  },
  components: {
    ApexCharts,
    ShipbluCard
  }
}
</script>