<template>
  <shipblu-card :cardLoading="averageTimeLoader" class="p-3">
    <p class="font-medium text-xl lg:mb-10 mb-3">{{ $t('Average Delivery Time') }}</p>
    <div class="px-4">
      <p class="text-center">
        <span class="text-primary font-semibold lg:leading-normal leading-tight" style="font-size: 70px;">{{ averageDeliveryTime }}</span>
        <span class="text-grey font-semibold text-4xl">{{ ' ' + $t('Days') }}</span>
      </p>
      <div :class="`flex ${(averageDeliveryTime >= 0 && averageDeliveryTime <= 3) ? 'justify-start' : (averageDeliveryTime >= 4 && averageDeliveryTime <= 5) ? 'justify-center' : 'justify-end'}`">
        <div class="flex w-1/3 justify-center px-1">
          <span class="material-icons-outlined text-primary lg:text-6xl text-5xl">arrow_drop_down</span>
        </div>
      </div>
      <div class="flex gap-1">
        <div class="h-3 w-1/3 rounded-r-none rounded-full" style="background: #34B475;"></div>
        <div class="h-3 w-1/3" style="background: #DDB756;"></div>
        <div class="h-3 w-1/3 rounded-l-none rounded-full" style="background: #DD5656;"></div>
      </div>
    </div>
  </shipblu-card>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'

export default {
  props:['averageDeliveryTime', 'averageTimeLoader'],
  components: {
    ShipbluCard
  }
}
</script>