<template>
  <div class="grid sm:grid-cols-2 grid-cols-1 gap-5">
    <shipblu-card :cardLoading="loaders.totalPickedLoader" class="p-3">
      <p class="mb-3">
        <span class="font-medium text-xl">{{ $t('Total Picked') }}</span>
        <vx-tooltip text="" class="inline-block">
          <span class="material-icons-round text-black text-base mx-1 cursor-default hidden">info</span>
        </vx-tooltip>
      </p>
      <p class="text-primary font-semibold text-4xl px-4">
        {{ statisticsData.length > 0 && statisticsData.filter(item => item.orders_pickup_count).length > 0 ? statisticsData.filter(item => item.orders_pickup_count)[0].orders_pickup_count.toLocaleString('en') : '0' }}
      </p>
    </shipblu-card>

    <shipblu-card :cardLoading="loaders.totalDeliveredLoader" class="p-3">
      <p class="mb-3">
        <span class="font-medium text-xl">{{ $t('Total Delivered') }}</span>
        <vx-tooltip text="" class="inline-block">
          <span class="material-icons-round text-black text-base mx-1 cursor-default hidden">info</span>
        </vx-tooltip>
      </p>
      <div class="flex justify-between px-4">
        <p class="text-primary font-semibold text-4xl">
          {{ statisticsData.length > 0 && statisticsData.filter(item => item.orders_delivered_count).length > 0 ? statisticsData.filter(item => item.orders_delivered_count)[0].orders_delivered_count.toLocaleString('en') : '0' }}
        </p>
        <p class="text-grey font-semibold text-4xl">
          {{ statisticsData.length > 0 && statisticsData.filter(item => item.delivered_percentage).length > 0 ? statisticsData.filter(item => item.delivered_percentage)[0].delivered_percentage + '%' : '0%' }}
        </p>
      </div>
    </shipblu-card>

    <shipblu-card :cardLoading="loaders.totalReturnedLoader" class="p-3">
      <p class="mb-3">
        <span class="font-medium text-xl">{{ $t('Total Returned') }}</span>
        <vx-tooltip text="" class="inline-block">
          <span class="material-icons-round text-black text-base mx-1 cursor-default hidden">info</span>
        </vx-tooltip>
      </p>
      <div class="flex justify-between px-4">
        <p class="text-primary font-semibold text-4xl">
          {{ statisticsData.length > 0 && statisticsData.filter(item => item.orders_returned_count).length > 0 ? statisticsData.filter(item => item.orders_returned_count)[0].orders_returned_count.toLocaleString('en') : '0' }}
        </p>
        <p class="text-grey font-semibold text-4xl">
          {{ statisticsData.length > 0 && statisticsData.filter(item => item.returned_percentage).length > 0 ? statisticsData.filter(item => item.returned_percentage)[0].returned_percentage + '%' : '0%' }}
        </p>
      </div>
    </shipblu-card>

    <shipblu-card :cardLoading="loaders.inProgressLoader" class="p-3">
      <p class="mb-3">
        <span class="font-medium text-xl">{{ $t('In Progress') }}</span>
        <vx-tooltip text="" class="inline-block">
          <span class="material-icons-round text-black text-base mx-1 cursor-default hidden">info</span>
        </vx-tooltip>
      </p>
      <div class="flex justify-between px-4">
        <p class="text-primary font-semibold text-4xl">
          {{ statisticsData.length > 0 && statisticsData.filter(item => item.orders_in_progress_count).length > 0 ? statisticsData.filter(item => item.orders_in_progress_count)[0].orders_in_progress_count.toLocaleString('en') : '0' }}
        </p>
        <p class="text-grey font-semibold text-4xl">
          {{ statisticsData.length > 0 && statisticsData.filter(item => item.in_progress_percentage).length > 0 ? statisticsData.filter(item => item.in_progress_percentage)[0].in_progress_percentage + '%' : '0%' }}
        </p>
      </div>
    </shipblu-card>
  </div>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'

export default {
  props: ['statisticsData', 'loaders'],
  components: {
    ShipbluCard
  }
}
</script>
