<template>
  <shipblu-card :cardLoading="successRateLoader" class="p-2">
    <p class="font-medium text-xl p-1">{{ $t('Delivery Success Rate') }}</p>
    <div class="chart-container m-auto mt-2">
      <progress :class="$i18n.locale === 'ar' ? 'rotate-270' : 'rotate-90'" class="progress-circle" :value="`${deliveryRate.delivery_success_rate ? deliveryRate.delivery_success_rate.toLocaleString('en', {minimumFractionDigits: 1, maximumFractionDigits: 1}) : 0}`" max="100" :style="`--progress:${deliveryRate.delivery_success_rate}`"></progress>
    </div>
    <div class="flex items-center gapx-2 justify-between card-footer rounded-t-none rounded-lg py-2 px-5 mt-4">
      <div class="flex items-center gap-2">
        <img src="@/assets/images/pages/prize.svg" width="25" alt="" />
        <p class="font-semibold text-black leading-none">{{$t('1st Attempt Delivery Rate:')}}</p>
      </div>
      <p class="font-semibold text-blue-100 text-lg leading-none ml-auto">{{ deliveryRate.first_attempt_delivery_rate ? deliveryRate.first_attempt_delivery_rate.toLocaleString('en', {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%' : '0%'}}</p>
    </div>
  </shipblu-card>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'

export default {
  props: ['deliveryRate', 'successRateLoader'],
  components: {
    ShipbluCard
  }
}
</script>

<style lang="scss">
.chart-container {
  width: 155px;
  height: 155px;
  ::-webkit-progress-inner-element, ::-webkit-progress-bar, ::-webkit-progress-value {
    display: none;
  }
  ::-moz-progress-bar {
    background: transparent;
  }
  .progress-circle {
    &.rotate-90 {
      transform: rotate(-90deg);
      &::after {
        content: attr(value) "%";
        transform: rotate(90deg);
      }
    }
    &.rotate-270 {
      transform: rotate(-270deg);
      &::after {
        content: attr(value) "%";
        transform: rotate(270deg);
      }
    }
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    --fill: calc(var(--progress) * 1%);
    background: conic-gradient(#1c5bfe var(--fill), lightgrey 0);
    &::after {
      position: absolute;
      inset: 25px;
      background: white;
      border-radius: 50%;
      color: #1c5bfe;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: 600;
    }
  }
}
.card-footer {
  background-color: #ECF3FB;
}
</style>