<template>
  <shipblu-card :cardLoading="attemptsLoader" class="p-3">
    <p class="font-medium text-xl mb-3">{{ $t('Delivery Attempts') }}</p>
    <apex-charts style="direction: ltr;" type="bar" height="230" :options="chartOptions" :series="series"></apex-charts>
  </shipblu-card>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import ApexCharts from 'vue-apexcharts'

export default {
  props: ['attempts', 'attemptsLoader'],
  data () {
    return {
      series: [
        {
          name: 'Attempts',
          data: []
        }
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 250,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true
          }
        },
        colors:['#1c5bfe'],
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: ['3rd.', '2nd.', '1st.']
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '12px',
              fontWeight: 600
            }
          }
        }
      }
    }
  },
  watch: {
    'attempts' (val) {
      if (val) {
        this.series[0].data = [this.attempts.orders_delivered_third_attempt_count, this.attempts.orders_delivered_second_attempt_count, this.attempts.orders_delivered_first_attempt_count]
      }
    }
  },
  components: {
    ShipbluCard,
    ApexCharts
  }
}
</script>