<template>
<div>
  <div>
    <h2 class="mb-6">{{$t('Analytics')}}</h2>
    <shipblu-card class="px-5 py-4 mb-5">
      <delivery-performance-date @callData="callData" @startDate="startDate = $event" @endDate="endDate = $event" />
    </shipblu-card>
    <shipblu-card class="p-5 grid grid-cols-5 gap-5">
      <statistics :statisticsData="statisticsData" :loaders="statisticsLoaders" class="lg:col-span-3 col-span-5" />
      <delivery-success-rate :deliveryRate="deliveryRate" :successRateLoader="successRateLoader" class="lg:col-span-2 col-span-5" />
      <delivery-attempts :attempts="attempts" :attemptsLoader="attemptsLoader" class="lg:col-span-3 col-span-5" />
      <average-delivery-time :averageDeliveryTime="averageDeliveryTime" :averageTimeLoader="averageTimeLoader" class="lg:col-span-2 col-span-5" />
      <geographical-analysis :geoData="geoData" :merchantID="merchantID" :startDate="startDate" :endDate="endDate" class="col-span-5" />
      <last-attempt-reason-distribution-table class="lg:col-span-3 col-span-5" :lastAttemptReason="lastAttemptReason" :lastAttemptReasonLoader="lastAttemptReasonLoader" />
      <package-distribution-pie-chart class="lg:col-span-2 col-span-5" :packageDistribution="packageDistribution" :packageDistributionLoader="packageDistributionLoader" />
    </shipblu-card>
  </div>
</div>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import DeliveryPerformanceDate from './analytics/DeliveryPerformanceDate.vue'
import Statistics from './analytics/Statistics.vue'
import DeliverySuccessRate from './analytics/DeliverySuccessRate.vue'
import DeliveryAttempts from './analytics/DeliveryAttempts.vue'
import AverageDeliveryTime from './analytics/AverageDeliveryTime.vue'
import { sendRequest } from '../../http/axios/requestHelper'
import PackageDistributionPieChart from './analytics/PackageDistributionPieChart.vue'
import LastAttemptReasonDistributionTable from './analytics/LastAttemptReasonDistributionTable.vue'
import GeographicalAnalysis from './analytics/GeographicalAnalysis.vue'

export default {
  data () {
    return {
      merchantID: '',
      startDate: '',
      endDate: '',
      statisticsLoaders: {
        totalPickedLoader: false,
        totalDeliveredLoader: false,
        totalReturnedLoader: false,
        inProgressLoader: false
      },
      attemptsLoader: false,
      averageTimeLoader: false,
      successRateLoader: false,
      packageDistributionLoader: false,
      lastAttemptReasonLoader: false,
      packageDistribution: [],
      lastAttemptReason: [],
      statisticsData: [],
      attempts: [],
      deliveryRate: {},
      averageDeliveryTime: 0,
      geoData: []
    }
  },
  methods: {
    loadStatistics (loader, endpoint, params) {
      this.statisticsLoaders[loader] = true
      sendRequest(true, false, this, `api/v1/dash/analytical/delivery-performance/${endpoint}?${params}`, 'get', null, true, 
        (response) => {
          this.statisticsData = [...this.statisticsData, response.data]
          this.statisticsLoaders[loader] = false
        }
      )
    },
    loadSuccessRate () {
      this.successRateLoader = true
      sendRequest(true, false, this, `api/v1/dash/analytical/delivery-performance/delivery-success-rate?merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`, 'get', null, true, 
        (response) => {
          this.deliveryRate = response.data
          this.successRateLoader = false
        }
      )
    },
    loadDeliveryAttempts () {
      this.attemptsLoader = true
      sendRequest(true, false, this, `api/v1/dash/analytical/delivery-performance/attempts-number?merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`, 'get', null, true, 
        (response) => {
          this.attempts = response.data
          this.attemptsLoader = false
        }
      )
    },
    loadAverageDeliveryTime () {
      this.averageTimeLoader = true
      sendRequest(true, false, this, `api/v1/dash/analytical/delivery-performance/average-delivery-time?merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`, 'get', null, true, 
        (response) => {
          this.averageDeliveryTime = response.data.delivered_orders_cycle_avg
          this.averageTimeLoader = false
        }
      )
    },
    loadGeoData () {
      sendRequest(true, false, this, `api/v1/dash/analytical/delivery-performance/governorates-distribution?merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`, 'get', null, true,
        (response) => {
          const data = response.data
          let otherOrdersPickupCount = 0
          data.slice(Math.max(4, 0)).map(item => {
            otherOrdersPickupCount += item.orders_pickup_count
          })
          this.geoData = data.slice(0, 4)
          this.geoData.push({
            governorate: 'Others',
            orders_pickup_count: otherOrdersPickupCount
          })
        }
      )
    },
    DataPackageDistributionPieChart () {
      this.packageDistributionLoader = true
      sendRequest(true, false, this, `api/v1/dash/analytical/delivery-performance/packages-size?merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`, 'get', null, true, 
        (response) => {
          this.packageDistribution = response.data
          this.packageDistributionLoader = false
        }
      )
    },
    DataLastAttemptReason () {
      this.lastAttemptReasonLoader = true
      sendRequest(true, false, this, `api/v1/dash/analytical/delivery-performance/attempt-reasons-distribution?merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`, 'get', null, true, 
        (response) => {
          this.lastAttemptReason = response.data
          this.lastAttemptReasonLoader = false
        }
      )
    },
    callData () {
      this.statisticsData = []
      this.loadStatistics('totalPickedLoader', 'orders-pickup', `merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`)
      this.loadStatistics('totalDeliveredLoader', 'orders-returned', `merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`)
      this.loadStatistics('totalReturnedLoader', 'orders-delivered', `merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`)
      this.loadStatistics('inProgressLoader', 'orders-inprogress', `merchant_id=${this.merchantID}&from_date=${this.startDate}&to_date=${this.endDate}`)
      this.loadSuccessRate()
      this.loadDeliveryAttempts()
      this.loadAverageDeliveryTime()
      this.loadGeoData()
      this.DataPackageDistributionPieChart()
      this.DataLastAttemptReason()
    }
  },
  components: {
    ShipbluCard,
    DeliveryPerformanceDate,
    Statistics,
    DeliverySuccessRate,
    DeliveryAttempts,
    AverageDeliveryTime,
    GeographicalAnalysis,
    PackageDistributionPieChart,
    LastAttemptReasonDistributionTable
  },
  created () {
    this.merchantID = JSON.parse(localStorage.getItem('merchant') !== null) ? JSON.parse(localStorage.getItem('merchant')).id : ''
  }
}
</script>